i
frame#myId {
   position: absolute;
   height: 100%;
   width: 100%;
}

.app-search .srh-btn {
   position: absolute;
   top: 0px;
   right: 0px;
   width: 0px;
   height: 0px;
   cursor: pointer;
   font-size: 14px;
   min-width: 40px;
   min-height: 36px;
   background-color: #02bcd4;
   border-top-right-radius: 5px;
   border-bottom-right-radius: 5px;
   border: solid 0.5px rgba(0, 0, 0, 0.24);
   /* margin-left: -25px; */
   margin-top: 8px;
   line-height: 25px;
}
.app-search  {

   margin-top: 8px;
  
}

.sweet-alert h2 {
   text-align: center;
   font-size: 20px;
   margin-bottom: 20px;
}

button.btn.btn-lg.btn-default {
   border: 2px solid #0000002b;
}

.swagger-ui section.models.is-open {
   padding: 0 0 20px;
   display: none;
}

.swagger-ui .scheme-container {
   background: #f2f7f8 !important;
   box-shadow: 0 0px 0px 0 rgb(0 0 0 / 0%) !important;
   margin: 0 0 0px 20px !important;
   padding: 0px 0 !important;
}
textarea.textarea-api.form-control {
   height: 150px;
}

span.copy-clip {
   /* float: right; */
   margin-left: 10px;
   font-size: 12px;
   color: #76889c;
   font-weight: 500;
   cursor: copy;
}



.swagger-ui input[disabled] , .swagger-ui .parameters-col_description input[type=text]{
   display: none;
}

.swagger-ui .opblock.opblock-post .opblock-summary-method {
   background: #00bcd4;
}

span.beta-login {
   color: #808080;
   position: absolute;
   font-size: 16px;
   margin-top: -4px;
   font-weight: 400;  
}

.swagger-ui .loading-container {
   align-items: center;
   display: flex;
}

.swagger-ui .loading-container .loading {
   position: relative;
   left: 0%;
}

/* Chip.css */
.chip {
   display: inline-flex;
   align-items: center;
   /* background-color: #e0e0e0; */
   background-color: white;
   /* border-radius: 16px; */
   border-radius: 5px;
   padding: 0 12px;
   font-size: 14px;
   font-weight: 500;
   height: 32px;
   margin: 4px;
   cursor: pointer;
   border: solid 2px #C00000 
 }
 
 .chip-label {
   color: #333;
   margin-right: 8px;
 }
 
 .chip-close {
   font-size: 16px;
   color: #888;
   cursor: pointer;
   padding-left: 8px;
 }
 
 .chip-close:hover {
   color: #444;
 }

 .chip:hover {
   background-color: #d0d0d0;
 }
 
 .chip-close:focus {
   outline: none;
 }

 /* Tooltip.css */
.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-box {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  bottom: 125%; /* Position above the element */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
  z-index: 1; /* Make sure the tooltip appears above other content */
  text-wrap:wrap;
  width:250px
}

.tooltip-box.left {
  left: 0;
  transform: none;
}

.tooltip-container:hover .tooltip-box {
  opacity: 1;
  visibility: visible;
}

.tooltip-box::after {
  content: '';
  position: absolute;
  top: 100%; /* Arrow pointing down */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

/* @media (min-width: 767px) {
/* .authentications {
   overflow: hidden;
   /* position: absolute; 
} 
} */

i.mdi.mdi-refresh {
   color: white;
}

/* #main-wrapper[data-layout="vertical"][data-header-position="fixed"] .topbar {
   position: fixed;
   width: 0 !important;
}

#main-wrapper[data-layout="vertical"][data-header-position="fixed"] .page-wrapper {
   padding-top: 0px !important;
} */

.scroll-sidebar {
   position: relative;
   height: calc(100% - 4%);
}

.parent-table-index-content-wrap{
  padding: 0.5rem 0rem 0.5rem 0rem !important
}

@media (max-width: 991.98px)
{
   .api-refrence-footer {
      
      width: 100% ;
      margin-left: 0px;
   }

   .topbar .top-navbar .navbar-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 20%;
   }

img.logo-home {
      margin-top: -45px;
  }

  span.beta-login {
   margin-top: -35px;
}
}

/* @media (min-width: 768px)
{
   #main-wrapper[data-layout="vertical"][data-sidebartype="full"] .topbar .top-navbar .navbar-header {
      width: 190px;
      margin-left: 1%;
   }
} */

.page-wrapper > .page-content {
   padding: 5px;
   /* min-height: calc(100vh - 25px); */
   min-height: 85vh ;
   /* //height: 96vh; */
   
}
.topbar .top-navbar {
   min-height: 60px;
   padding: 0;
}

.table {
   
   margin-bottom: 0;
  
}
.table-striped tbody tr:nth-of-type(odd) {
   background-color: #ffffff;
}
.table-striped tbody tr:nth-of-type(even) {
   background-color: #faf9f9;
}
.table-striped tbody tr:nth-of-type(odd):hover {
   background-color:#f8f7f7;
}
.table-striped tbody tr:nth-of-type(even):hover {
   background-color:#e9e7e7;
}

.sidebar-search-btn{
   padding: 5px 5px;
}

i.fas.fa-eye {
   color: #00bcd4;
}

input.search-input.form-control {
   width: 75%;
   height: calc(1em + 0.75rem + 2px);
   padding: 0.375rem 0.75rem;
}

button.search-button {
   height: calc(1em + 0.75rem + 2px);
   padding: 5px 10px;
}



.search-form.input-group {
   width: 70%;
   float: right;
}

.iframe-button{
   height:85vh;
   /* height:100%; */
}


@media screen and (min-width: 1080px) and (max-width: 1900px) {
   .iframe-button{
      height:550px;
   }
}
/* @media screen and (width: 1280px) {
  #root{
   transform: scale(0.86);
   width: 114vw;
   position: relative;
   bottom: -120px;
   margin-top: -160px;
   left: -8vw;
  }
} */
.modal-dialog.undefinedlogs-modal .modal-content {
   width: 750px;
}

#main-wrapper[data-layout="vertical"] .left-sidebar[data-sidebarbg="skin6"] .sidebar-nav ul .sidebar-item .sidebar-link.link-disabled {
   color: #a9a3a3;
   opacity: 1;
   pointer-events: "none"
}

.topbar .top-navbar .navbar-header .navbar-brand {
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0 70px;
   margin: 0;
}

.topbar {
   position: relative;
   height: 60px;
}

button.search-button.btn.btn-secondary{
   border-color: black;
   background-color: #02bcd4;
   height: 28px;

}

button.search-button.btn.btn-warning {
   height: 28px;
}

button.search-button.search-warning.btn.btn-warning {
   border-color: black;
}

.topbar .top-navbar .navbar-nav > .nav-item > .nav-link {
   line-height: 10px;
   height: 40px;
   padding: 5px 0.75rem;
   font-size: 14px;
}

/* .topbar .top-navbar {
   min-height: 50px;
   padding: 0;
} */

.navbar-nav {
   margin-top: -20px;
 
}

.table-tr{
   line-height: 1;
   background-color: #ffffff;

}
.anchortextpresent{
   color: red;
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6;
    padding: 0 0 0 1.5rem;
}
.table-bordered th, .table-bordered td {
   /* border: 1px solid #e8eef3; */
   border-left:none ;
   border-right: none;
}

.table thead th, .table tfoot th, .table th {
   font-weight: 500;
   background-color: #E2FCFF;
   color: black;

}

.theme-color .theme-item .theme-link[data-navbarbg="skin1"], #main-wrapper[data-layout="vertical"] .topbar .navbar-collapse[data-navbarbg="skin1"], #main-wrapper[data-layout="vertical"] .topbar[data-navbarbg="skin1"], #main-wrapper[data-layout="horizontal"] .topbar .navbar-collapse[data-navbarbg="skin1"], #main-wrapper[data-layout="horizontal"] .topbar[data-navbarbg="skin1"] {
   background: white;
}

@media (max-width: 991.98px)
{
   .topbar .top-navbar .navbar-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 0%;
   }

   .topbar .nav-toggler, .topbar .topbartoggler {
      color: #fff;
      padding: 0px 20px;
   }

   span.logo-text {
      display: none;
   }

   .topbar .top-navbar .navbar-header .navbar-brand .logo-icon {
      /* margin-right: 35px; */
      margin-left: 10px;
      margin-top: -60px;
   }

   span.topbartoggler.d-block.d-md-none.mob-tonggle {
      margin-left: -50px;
  }

   .topbar .top-navbar .navbar-nav {
      flex-direction: row;
      margin-top: -5px;
      padding: 5px;
  }

  .topbar .top-navbar .navbar-collapse.show, .topbar .top-navbar .navbar-collapse.collapsing {
   display: block;
   width: 100%;
   border-top: unset;
}
}

@media (max-width: 766px)
{
   #main-wrapper[data-layout="vertical"][data-sidebar-position="fixed"] .topbar .top-navbar .navbar-collapse, #main-wrapper[data-layout="vertical"][data-header-position="fixed"][data-sidebar-position="fixed"] .topbar .top-navbar .navbar-collapse {
      position: relative;
      top: 40px;
   }
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}



.settingIcon-header {
   position: absolute;
   right: 20px;
   margin-top: 5px;
   cursor: pointer;
   font-size: 22px;
}

@media (max-width: 991.98px)
{

   .swagger-ui .opblock-tag {
      color: #3b4151;
      font-family: sans-serif;
      font-size: 16px;
      margin: 0 0 5px;
  }

.topbar .nav-toggler, .topbar .topbartoggler {
    color: #fff;
    padding: 0px 0px 0px 100px;
}

.settingIcon-header {
   position: absolute;
   right: 80px;
   margin-top: 5px;
   cursor: pointer;
   font-size: 22px;
}
}

.btn-circle.btn-sm, .btn-group-sm > .btn-circle.btn {
   width: 35px;
   height: 35px;
   padding: 3px 8px;
   font-size: 14px;
}

.btn-color-diro {
   background-color: rgb(223,229,231) !important;
}
.btn-info {
   color: rgb(0, 188, 212);
   background-color: #009efb;
   border-color: rgb(0, 188, 212);
   /* box-shadow: 0 1px 0 rgb(255 255 255 / 15%); */
}



.custom-control-input:checked ~ .custom-control-label::before {
   color: #fff;
   border-color: rgb(0, 188, 212) !important;
   background-color: rgb(0, 188, 212) !important;
   box-shadow: none;
}

.btn-color-diro-outline {
   /* background-color: rgb(0, 188, 212); */
   border-color: rgb(0, 188, 212);
   color:rgb(0, 188, 212);
}

.btn-color-diro-outline:not(:disabled):not(.disabled).active {
   color: #fff;
   background-color: rgb(0, 188, 212);
   border-color: rgb(0, 188, 212);
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
   color: #fff;
   background-color: rgb(0, 188, 212);
   border-color: rgb(0, 188, 212);
}

.btn-color-diro-light{
   background-color: #00bcd4b5;
}

.ml-10 {
   margin-left: 35%;
}

button.accordion-button {
   width: 100%;
   text-align: left;
   padding: 8px 10px;
   background: white;
   border: 1px solid #e9ecef !important;
   color: #54667a;
}

h2.accordion-header {
   font-size: 18px;
   margin-bottom: 0px;
   color: #54667a;
}

.text-muted {
   color: #90a4ae !important;
   margin-top: 0px;
}

.cross-field {
   margin-top: 10px;
}

.card {
   border-radius: 0;
   background-color: #f2f7f8;
}

.asterisk{
   color:red;
}

small.cus-temp {
   text-align: right;
   color: #00bcd4 !important;
   font-size: 16px;
}

.template-modal-color{
   background-color: #FBF4E5;
}
ul.help_box {
   background-color: white;
}

span.button-id-header {
   font-size: 14px;
   margin-top: 22px;
   margin-left: -15px;
}

.btn-outline-primary:hover {
   color: #fff;
   background-color: #00bcd4;
   border-color: #00bcd4;
}

.btn-success:hover {
   color: #fff;
   background-color: #00bcd4b5;
   border-color: #00bcd4b5;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
   color: #fff;
   background-color: #00bcd4;
   border-color: #00bcd4b5;
}


@media (max-width: 991.98px)
{
   .topbar .top-navbar .navbar-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      left: -76px;
      width: 0%;
   }

   .font-weight-medium.rounded-top.py-3.px-4{
      color: rgb(33, 37, 41);
      font-weight: 400;
      letter-spacing: 2px;
      font-size: 17px;
      display: flex;
      margin-top: 7px !important;
      margin-left: 20px !important;
   }

}

.card .card-title {
   margin-bottom: 10px;
   font-weight: 500;
   font-size: 20px;
   color: #2c2b2e;
}

textarea.textarea-reqdoc.form-control {
   height: 150px;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
   color: #fff;
   background-color: #5ac8da;
   border-color: #5ac8da;
}

.btn-primary {
   color: #fff;
   background-color: #00bcd4b5;
   border-color: #00bcd4b5;
   box-shadow: 0 1px 0 rgb(255 255 255 / 15%);
}

.btn-primary:hover {
   color: #fff;
   background-color: #5ac8da;
   border-color: #00bcd4b5;
}

button.btn.btn-diro-tongle-primary.active
{
   color: #fff;
   background-color: #5ac8da;
   border-color: #5ac8da;
}

/*  */
.btn-danger {
   background-color: #e96980;
}

.swagger-ui .opblock.opblock-post .opblock-summary-method {
   background: #00bcd4 !important;
}

.swagger-ui .opblock.opblock-post .opblock-summary {
   border-color: #00bcd4 !important;
}

.swagger-ui .opblock.opblock-post {
   background: rgb(0 201 250 / 12%) !important;
   border-color: #00bcd4 !important;
}

.btn-success.focus, .btn-success:focus, .btn-success:hover {
   background-color: #00bcd4;
   border-color: #00bcd4;
   box-shadow: rgb(0 201 250 / 12%) !important;
}
.btn-success {
   color: #fff;
   background-color: #00bcd4;
   border-color: #00bcd4;
}

.no-data-found {
   text-align: center;
   height: 80vh;
}

.cog-header{
   color:#ffbc34;
}

 .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link .btn_lnk {
   /* color: #fff; */
   font-size: 16px;
   font-weight: 500;

   /* border-top-left-radius: 12px; */
   /* border-top-right-radius: 12px; */
   background-color:#00BCD4;
   color: #FFFFFF !important;
   /* background-color: #dee2e6; */
   /* border-color: #dee2e6 #dee2e6 #dee2e6; */
   border-color: rgba(30, 42, 53, 0.16);
} 
.nav-tabs .nav-link {
   /* border: 1px solid transparent; */
   border-color: rgba(30, 42, 53, 0.16);

   border-top-left-radius: 12px;
   border-top-right-radius: 12px;
}

.nav-link{
   color:#000 !important;
   font-size: 18px;
   font-weight: 500;

} 
.nav-tabs .btn_lnk{
   color:#000 !important;
   display: flex;
   align-items: center;
   justify-content: center;
    border: 1px solid rgba(30, 42, 53, 0.16);
   border-top-left-radius: 12px;
   border-top-right-radius: 12px;
   cursor: pointer;
   line-height: 10px;
   height: 40px;
   padding: 5px 0.75rem;
   font-size: 14px;

   font-weight: 500;

   /* border-top-left-radius: 12px; */
   /* border-top-right-radius: 12px; */
   

   /* background-color: #dee2e6; */
   /* border-color: #dee2e6 #dee2e6 #dee2e6; */
   border-color: rgba(30, 42, 53, 0.16);
}
.custom-select {
   border: 1px solid #000000;
}

.mrt-20 {
   margin-top: -20px;
}

.br-4{
   border-radius: 4px;
}

.col-red{
   color: #e96980;
   font-size: 20px;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary, .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary {
   color: #fff;
   background: #00bcd4 !important;
}

.bootstrap-switch .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-handle-on, .bootstrap-switch .bootstrap-switch-label {
   
   padding: 3px 12px !important;
   font-size: 12px;
   line-height: 18px !important;
}

#operations-tag-No_code_integration {
   display: flex;
   flex-direction: column;
   text-align: left;
   left: 0;
   align-items: baseline;
}

#operations-tag-No_code_integration button.expand-operation {
   display: none;
}

.markdown pre {
   /* background-color: white !important; */
   background: rgb(0 201 250 / 12%) !important;
   
}

h3#operations-tag-No_code_integration a {
   height: 40px;
   padding: 5px;
   background: rgb(0 201 250 / 12%) !important;
   width: 100%;
   border-radius: 5px;
  

}

#operations-tag-No_code_integration {
   display: flex;
   flex-direction: column;
   text-align: left;
   left: 0;
   align-items: baseline;
   margin-top: 15px;
}

a {
   color: #868e96;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default, .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default {
   color: #000;
   background: #e969803d;
}

.header-tonggle {
   margin-top: 12px;
   margin-right: 5px;
}

.border {
   border: 1px solid black !important;
}
.border-lightsky {
   border: 1px solid darkturquoise !important;
   background-color: lightblue;

}
.lightsky {
 
   background-color: lightblue;

}
.border-lightsky{
   border: 1px solid darkturquoise;
}

::-webkit-input-placeholder { /* Edge */
   color: #acb0b6b5 !important;
 }
 
 :-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #acb0b6b5 !important;
 }
 
 ::placeholder {
   color: #acb0b6b5 !important;
 }

 .or.col-md-1 {
   margin-top: 2rem;
}

li.nav-item.search-box.d-none.d-md-block.margin-left-tongle {
   margin-left: -65px;
}

.marginLeft-minus-50 {
   margin-left: -50px;
}

button:focus {
   outline: 1px solid !important;
}

img.screenshot-img-adv-setting-io {
   width: 75%;
   margin-top:-10px;
   /* margin: -40px 0 0 40px; */
}

.sample-screen-img {
   margin-top: -60px;
}

span.acc-head {
   margin-left: 10px;
   margin-top: 7px;
}

img.modal-screenshot-img {
   width: 100%;
}

.btn-primary:focus, .btn-primary.focus {
   color: #fff;
   background-color: #00bcd4b5;
   border-color: #00bcd4b5;
   box-shadow: 0 1px 0 rgb(255 255 255 / 15%), 0 0 0 0.2rem rgb(137 120 241 / 50%);
}

img.api-interaction-img {
   width: 70%;
   margin: 0 auto;
   display: block;
}

@media (max-width: 767px) {
   .swagger-ui .opblock-tag small { 
       flex: unset !important;
   }

   .swagger-ui .opblock .opblock-summary-operation-id, .swagger-ui .opblock .opblock-summary-path, .swagger-ui .opblock .opblock-summary-path__deprecated {
      /* margin-right: 125px; */
       word-break: unset !important; 
  }

  .swagger-ui .opblock .opblock-summary-description {
      display: none;
   }

    .swagger-ui .opblock .opblock-summary-path {
      flex-shrink: 0;
      max-width: calc(100% - 71px - 1rem) !important;
  } 

  .swagger-ui .opblock-tag {
      font-size: 16px;
  }

  .swagger-ui .markdown p, .swagger-ui .markdown pre, .swagger-ui .renderedMarkdown p, .swagger-ui .renderedMarkdown pre {
      margin: 1em auto;
      word-break: break-all;
      word-break: break-word;
      font-size: 8px !important;
   }

   .body-param {
      margin-left: -50px !important;
   }

   .swagger-ui .opblock .opblock-section-header {
      display: unset !important; 
   }

   .swagger-ui .opblock .opblock-section-header {
      background: unset !important;
   }
}

a.sidebar-link.side-second-sub-menu{
   padding: 3px 5px 5px 30px !important;
}

ul.second-level {
   background-color: #dcf1f9 !important;
}

 span.sidebar-link.has-arrow.side-sub-menu {
   padding: 12px 15px 12px 22px !important;
}
.side-sub-menu {
   padding: 12px 15px 12px 22px !important;
} 

#main-wrapper[data-layout="vertical"] .left-sidebar[data-sidebarbg="skin6"] .sidebar-nav ul .sidebar-item .sidebar-link:hover .feather, #main-wrapper[data-layout="horizontal"] .left-sidebar[data-sidebarbg="skin6"] .sidebar-nav ul .sidebar-item .sidebar-link:hover .feather {
   color: #333;
}

span.hide-menu:hover {
   font-weight: 500;
} 

a.sidebar-link.side-second-sub-menu.active {
   font-weight: 500;
}

#main-wrapper[data-layout="vertical"] .left-sidebar[data-sidebarbg="skin6"] .sidebar-nav ul .sidebar-item.selected > .sidebar-link, #main-wrapper[data-layout="horizontal"] .left-sidebar[data-sidebarbg="skin6"] .sidebar-nav ul .sidebar-item.selected > .sidebar-link {
   border-left: 3px solid #00bcd4;
   color: #00bcd4;
    background: rgba(0, 0, 0, 0.04); 
   opacity: 1;
   font-weight: 500;
}
#main-wrapper[data-layout="vertical"] .left-sidebar[data-sidebarbg="skin6"] .sidebar-nav ul .sidebar-item.selected > .sidebar-link i, #main-wrapper[data-layout="vertical"] .left-sidebar[data-sidebarbg="skin6"] .sidebar-nav ul .sidebar-item.selected > .sidebar-link .feather, #main-wrapper[data-layout="horizontal"] .left-sidebar[data-sidebarbg="skin6"] .sidebar-nav ul .sidebar-item.selected > .sidebar-link i, #main-wrapper[data-layout="horizontal"] .left-sidebar[data-sidebarbg="skin6"] .sidebar-nav ul .sidebar-item.selected > .sidebar-link .feather {
   color: #00bcd4;
}

#main-wrapper[data-layout="vertical"] .left-sidebar[data-sidebarbg="skin6"] .sidebar-nav ul .sidebar-item .first-level .sidebar-item .sidebar-link.active, #main-wrapper[data-layout="horizontal"] .left-sidebar[data-sidebarbg="skin6"] .sidebar-nav ul .sidebar-item .first-level .sidebar-item .sidebar-link.active {
   color: #00bcd4;
}

.icon-size{
   font-size: 22px;
   color: #54667ac4;
}

.btn-without-bg {
   font-weight: 400;
   border: none;
   background: transparent;
   color: #333;
   text-decoration: none;
}

.page-item.active .page-link {
   z-index: 3;
   color: #fff;
   background-color: #00bcd4 !important;
   border-color: #00bcd4 !important;
}

b.logo-icon {
   margin-left: 35px;
}

.margin-min-4{
   margin-left: -65px;
   margin-top: -10px;
}

.swagger-ui .info {
   margin: 50px 0;
   display: none;
}

.swagger-ui .scheme-container {
   background: #fff;
   box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%);
   margin: 0 0 20px;
   padding: 30px 0;
   display: none;
}

img.interaction-review-img {
   margin: 0 auto;
   display: block;
   width: 25%;
}

.api-refrence-footer {
   position: fixed;
   right: 0;
   bottom: 0;
   background-color: #dcf1f9;
   color: white;
   text-align: center;
   padding: 10px;
   width: calc(100% - 240px);
   margin-left: 240px;
}

.swagger-api-ref {
   margin-bottom: 25px;
   margin-top: -90px;
}

p.no-code-para {
   font-size: 14px;
   line-height: 1.3;
}

a.nostyle.no-code-inte-api {
   border: 1px solid #00bcd4 !important;
   padding: 5px;
}

@media (min-width: 768px){
   .navbar-expand-md .navbar-nav {
      flex-direction: row;
      /* margin-left: 45px; */
  }

  .api-refrence-footer {
      position: fixed;
      right: 0;
      bottom: 0;
      background-color: #dcf1f9;
      color: white;
      text-align: center;
      padding: 10px;
      width: calc(100% - 240px);
      margin-left: 240px;
   }
}

.margin-min-alert{
   margin-bottom: -45px;
}

@media (max-width: 767px){
 

  .api-refrence-footer {
      position: fixed;
      right: 0;
      bottom: 0;
      background-color: #dcf1f9;
      color: white;
      text-align: center;
      padding: 10px;
      width: 100%;
      margin-left: 240px;
   }

   .swagger-api-ref {
      margin-bottom: 100px;
      
  }
}

.reactEasyCrop_Container {
   top: 15% !important;
   height: 50vh !important;
}

.img-crop-modal {
   height: 65vh;
}

.save-btn-img-crop {
   position: absolute;
   right: 15px;
   top: 10px;
}

.MuiSlider-root {
   width: 80% !important;
}

span.input-group-text {
   background-color: transparent;
   border: none;
}

button.btn.btn.btn-link.btn.btn-secondary {
   background-color: transparent;
   border: unset;
   color: #000;
}

.data-table-dropdown.dropdown-menu.show {
   top: -15px !important;
   left: -30px !important;
}
.lgid{
   background: #0E3F61;
   color: #ffffff;
}

.min-or {
   margin-top: -60px;
}

.view-light-box {
   padding: 6px 5px 0px 1px;
    background: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 5px;
}

.web_head {
   background-color: #00BCD433;
   border-radius: 20px 20px 0px 0px;
   padding: 8px 30px;
}

 .react-pdf__Page__canvas {
   width: 100% !important;
   height: unset !important;
} 

.react-pdf__Page__annotations.annotationLayer {
   display: none;
}

.react-pdf__Document {
   width: 100%;
   box-shadow: 0 0 6px 0 rgb(0 0 0 / 50%);
   background-color: #ffffff;
   border: solid 5px rgb(0 0 0 / 53%);
   margin: auto;
   margin-top: 16px;
   overflow: auto;
   height: 91vh;
}

.scrollbar-container {
   position: relative;
   height: 75%;
}

.pdf-view-nav-list{
   padding-left: 0;
   margin-bottom: 12px;
   list-style: none;
}

.list-pdf-view{
   border-radius: 4px;
    padding: 4px 8px;
    background-color: #0000000D;
    cursor: pointer;
    
}

.blue-color {
   color: rgb(0, 188, 212)
}


span.nostyle.no-code-inte-api {
   background: rgb(0 201 250 / 12%) !important;
   border-color: #00bcd4 !important;
   width: 100%;
   padding: 10px;
   border: 1px solid;
   border-radius: 5px;
}
.icon-hand
{
   cursor: pointer;
}

ul.billing-nickname-list {
   margin-left: -24px;
}
.cursor-pointer {
   cursor: pointer;
   
}

#cursor-pointer {
   cursor: pointer;
}

.button-post{
   border-radius: 8px;
   background-color:darkturquoise;
  border: none;
  color: white;
  padding: 1px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
}
.crmIntegrationButton{    
   right: 0;
   cursor: pointer;
   position: absolute;
   margin-top: -17px;
   margin-right: 16px;
  
}

.h-90
{
   height: 90% !important;
}
.dirodatadictionary{
   color: black;
}
.dirodatadictionary:hover{color: black;}

li.no-code-para {
   font-size: 14px;
}

ul.ul-list.no-code-para {
   margin-left: -20px;
}
ul.ul-list {
   margin-left: -25px;
}

span.right-json-button {
   right: 60px;
   position: absolute;
   
}
.question-btn{
   right: 60px;
}

.value-hi
{
   right: 50px;
   position: absolute;
}

.value-hi-data {
   width:65%;
   

}
.value-hiedit-data {

   /* margin-left: 65px; */

}
.field-hi-data {
   width:35%;

}

.overflow-hi{
   overflow: auto;
   height: 60vh;
}
.overflow-hiedit{
   overflow: auto;
   height: 70vh;
}

.pdftext{    overflow: overlay;}
.pdftextedit{    overflow: overlay;
   margin-left: 3px;
   margin-top: -2px;
   padding-bottom: 4px;
   margin-right: -1px;
   cursor: pointer;
}
.textbox{width:18rem}
.check{margin-right: 5px;}

.copy-button{min-width: max-content;}

.btnjson {
   display: inline-block;
   font-weight: 400;
   color: #f9fbfd;
   text-align: center;
   vertical-align: middle;
   cursor: pointer;
   -webkit-user-select: none;
   user-select: none;
   background-color: transparent;
   border: 1px solid transparent;
   padding: 2px 10px;
   margin: -5px 12px -8px -6px;
   font-size: 12px;
   line-height: 1.5;
   border-radius: 4px;
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.hieditsavebutton{
   margin-left: auto;
   margin-top: -14px;
   background: #0E3F61;
   color: #ffffff;
   border: none;
}
.hieditsavebuttontop{
   margin-left: 10px;
   background: #0E3F61;
   color: #ffffff;
   border: none;
}
.hieditsavebuttonQA{
   margin-left: 20px;
   background: #0E3F61;
   color: #ffffff;
   border: none;
}
.hieditaddfieldbutton{
   margin-top: -14px;
}
.hiedittestquebutton{
   margin-left: initial;
}
.hieditarrow{
   margin-left: auto;
   margin-right: 10px;
}

.textfield{   
  display:block;
}

.gpt-table-gpt4-btn{
   background-color: #007bff;
   color: white;
   border: none;
   border-radius: 4px;
   padding: 8px;
   cursor: pointer;
   width:max-content;
}
.gpt-table-btn-wrap{
 display:flex;
 gap:10px;
}

.gpt-table-input-wrap{
display: flex;
flex-direction: column;
gap: 10px;
width: 100%;
}

.rectanglefield{margin-right: 10px;
display: inline-block;}

.questionstyle{margin-left: 10px;}

.fieldColour{background-color: rgb(62, 190, 254);}

.question-field{width: 253px; margin-top: 5px;
}

.colour-black{color: black;}
.regexanswer{margin: inherit;}
.hiedittestregbutton{margin-left: 83px;}
.commentbox
{
   margin-left: 9px;
   color: black;
}
.comment-field{
   width: 317px;
margin-bottom:2px;
color: black;
}
.addfield-field{
   height: 24px;
}
.pendingtabdots{
   /* margin-right: -25px;
   margin-left: 26px; */
   margin-right: 9px;
   margin-left: 24px;

}
.btn-fld{
   color: rgb(0, 188, 212);
   background: none;
    border: none;
    text-decoration: underline;
    margin-left: 10px;
    cursor: pointer;
}
 
 .select-cat{
   background: #0E3F61 !important;
   color: #ffffff !important;
   margin-top: -6px;
 }
 .select-cat >select{
   background-color: none !important;
 }
 /* .select-cat >option{
   background: none !important;
 }
 .select-cat:hover{
   background: none !important;
 }
 /* .select-cat:active{
   background: none !important;
 } */
 /* .abc:acive{
   background-color:#fffffF !important;
 } */
 .textractblockdata{
   margin-top: 28px;
 }

.addbutton{
   margin-left: 766pt;
   margin-top: -14px;
   background: #0E3F61;
   color: #ffffff;
   border: none;
}
.editbutton{
      margin-left: 745pt;
      margin-top: -14px;
      background: #0E3F61;
      color: #ffffff;
      border: none;
}
.editmodal{
   background-color: rgb(235, 231, 231);
}
.headingcolour{
   color: rgb(181, 176, 176);
}
.cardcolour{
   border: 1px solid rgb(211, 203, 203) !important;
   background-color: white;
}
.hieditsendtobad{
   /* margin-left: 15cm; */
   margin-top: -14px;
   border: none;
}
.showpdf{
   margin-bottom: 30px;
}
.table-responsive {
	overflow-x: clip !important;
}
.editpdfnav li a, .editpdfnav li.nav-item a.active {
font-size: 15px;
}
.hieditchatgptprompt{
   border: none;
   margin: auto;
   margin-top: -8px;
}
@media screen and (max-width: 1280px) {
   .humanInterfaceEdit1{
      margin-bottom: 10px;
      margin-left:auto
      /* width: "calc(80vw - 725px)"; */
   }
}
@media screen and (min-width: 1280px) {
   .humanInterfaceEdit1{
      margin-bottom: 10px;
      margin-left:auto
      /* width: "calc(71vw - 725px)"; */
   }
}
/* Styles for the chat history */
.chat-history {
   max-height: 300px;
   overflow-y: scroll;
   border: 1px solid #ccc;
   padding: 10px;
 }
 
 .message {
   margin: 10px;
   padding: 8px;
   border-radius: 8px;
 }
 
 .user {
   background-color: #f0f0f0;
 }
 
 .bot {
   background-color: #007bff;
   color: white;
 }
 
 /* Styles for the user input form */
 .user-input {
   display: flex;
   gap:18px;
   align-items: center;
   padding: 10px;
   border-top: 1px solid #ccc;
 }
 
 .user-input input[type="file"] {
   margin-right: 10px;
 }
 
 .user-input input[type="text"] {
   flex: 1;
   padding: 8px;
   border: 1px solid #ccc;
   border-radius: 4px;
 }
 
 .user-input button {
   background-color: #007bff;
   color: white;
   border: none;
   border-radius: 4px;
   padding: 8px 12px;
   cursor: pointer;
 }
 
 .user-input button:hover {
   background-color: #0056b3;
 }
 .custom-pdf-viewer.viewer-layout-container.viewer-layout-toolbar,
.custom-pdf-viewer.viewer-layout-container.viewer-layout-sidebar {
  display: none !important;
}
 
 .WhitelistTotalLinkData{
   display: inline-block;
   background-color: red; 
   color: white; 
   font-size: 15px;
   border-radius: 50%; 
   padding: 1.5px 1px 1.5px; 
 }
 .WhitelistTotalData{
   display: inline-block;
   background-color: red; 
   color: white; 
   font-size: 15px;
   border-radius: 50%; 
   padding: 1.0px 5px 0.5px; 
 }
/* .viewer-layout-toolbar{
   display: none !important;
}
.viewer-layout-sidebar{
   display: none !important;
} */
.modal-width{
   .modal-body{
      .viewer-layout-container{
         .viewer-layout-sidebar{
            display: none !important;
         }
      }
   }
}
.modal-width{
   .modal-body{
      .viewer-layout-container{
         .viewer-layout-toolbar{
            display: none !important;
         }
      }
   }
}

.modal-compare {
   width:52rem !important;
}
.modal-width {
   width:44rem !important;
}

.viewer-open-file{
   display: none !important;
}
.viewer-toolbar-right{
   .viewer-toolbar-item{
      display: none !important;
   }
}


