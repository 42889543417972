.fallback-spinner{
    position: relative;
    display: flex;
    width: 100%;
    height: 80vh;
}
.fallback-spinner-small{
  position: relative;
  display: flex;
  width: 0;
  height: 0;
  margin-left: 115px;
}
.fallback-spinner-small-questionmodal{
  position: absolute;
  display: flex;
  width: 0vw;
  height: 2vh;
  right: 370px;
}
.fallback-spinner-Qa{
  position: absolute;
  display: flex;
  left: 140px;
}
.loading-Qa {
  position: absolute;
  left: calc(50% - 50px);
  top: calc(135% - 50px);
  // top: 45%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;

  
  .effect-1,
  .effect-2 {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-left: 3px solid #868e96;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .effect-1 {
    animation: rotate 1s ease infinite;
  }
  .effect-2 {
    animation: rotateOpacity 1s ease infinite 0.1s;
  }
  .effect-3 {
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-left: 3px solid #868e96;
    -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
    animation: rotateOpacity 1s ease infinite 0.2s;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .loading .effects {
    transition: all 0.3s ease;
  }
}
.loading {
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
    // top: 45%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    width: 55px;
    height: 55px;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 3px solid transparent;
  
    
    .effect-1,
    .effect-2 {
      position: absolute;
      width: 100%;
      height: 100%;
      border: 3px solid transparent;
      border-left: 3px solid #868e96;
      border-radius: 50%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
  
    .effect-1 {
      animation: rotate 1s ease infinite;
    }
    .effect-2 {
      animation: rotateOpacity 1s ease infinite 0.1s;
    }
    .effect-3 {
      width: 100%;
      height: 100%;
      border: 3px solid transparent;
      border-left: 3px solid #868e96;
      -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
      animation: rotateOpacity 1s ease infinite 0.2s;
      border-radius: 50%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
  
    .loading .effects {
      transition: all 0.3s ease;
    }
  }
  .fallback-logo {
    position: absolute;
    left: calc(50% - 45px);
    top: 40%;
  }
  
  @keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
    }
  }
  @keyframes rotateOpacity {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      opacity: 0.1;
    }
    100% {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
      opacity: 1;
    }
  }
  